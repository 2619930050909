import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Typography,
  Alert,
} from '@mui/material';
import axios from 'axios';
import Layout from './layout';

const Logger = () => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLogs, setTotalLogs] = useState(0);
  const [error, setError] = useState('');

  const getStatusDescription = (status) => {
    if (status >= 200 && status < 300) return "Success";
    if (status >= 300 && status < 400) return "Redirect";
    if (status >= 400 && status < 500) return "Client Error";
    if (status >= 500) return "Server Error";
    return "Unknown"; // Default for any other status
  };

  useEffect(() => {
    const fetchAuditLogs = async () => {
      try {
        const response = await axios.get('https://api.ghsdginnovations.org/api/loggers/logs'); // Winston logs API
        const logs = response.data.logs.map((log, index) => {
          try {
            // Log the raw log content to help debug
            console.log('Raw Log:', log);
  
            // Check if the log is a valid JSON
            const parsedLog = isValidJson(log) ? JSON.parse(log) : null;
  
            if (parsedLog) {
              const parsedMessage = isValidJson(parsedLog.message) ? JSON.parse(parsedLog.message) : null;
  
              return {
                id: index,
                level: parsedLog.level,
                method: parsedMessage?.method,
                url: parsedMessage?.url,
                status: parsedMessage?.status,
                responseTime: parsedMessage?.responseTime,
                timestamp: parsedLog.timestamp || new Date().toISOString(),
              };
            } else {
              return null; // Skip invalid log
            }
          } catch (error) {
            console.error('Error parsing log:', error);
            return null; // Skip logs that cannot be parsed
          }
        }).filter(Boolean); // Remove null values
  
        setAuditLogs(logs);
        setTotalLogs(logs.length);
      } catch (error) {
        console.error('Error fetching audit logs:', error);
        setError('Error fetching logs. Please try again later.');
      }
    };
  
    const isValidJson = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };
  
    fetchAuditLogs();
  
    // Poll logs every 10 seconds
    const interval = setInterval(fetchAuditLogs, 10000);
    return () => clearInterval(interval);
  }, []);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function to get background color based on log level
  const getLevelColor = (level) => {
    switch (level) {
      case 'error': return '#ffcccc'; // Light red for errors
      case 'warn': return '#fff4cc'; // Light yellow for warnings
      case 'info': return '#cce5ff'; // Light blue for info
      case 'debug': return '#d4f4cc'; // Light green for debug
      default: return 'white'; // Default white
    }
  };

  return (
    <Layout>
      <Box marginTop="64px" marginBottom="30px">
        <Typography variant="h4" gutterBottom>
          System Logs
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                <TableCell><strong>Level</strong></TableCell>
                <TableCell><strong>Method</strong></TableCell>
                <TableCell><strong>URL</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>Response Time (ms)</strong></TableCell>
                <TableCell><strong>Timestamp</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditLogs.length > 0 ? (
                auditLogs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((log) => (
                    <TableRow key={log.id}>
                      <TableCell style={{ backgroundColor: getLevelColor(log.level), fontWeight: 'bold' }}>
                        {log.level}
                      </TableCell>
                      <TableCell>{log.method}</TableCell>
                      <TableCell>{log.url}</TableCell>
                      <TableCell
                        style={{
                          color:
                            log.status >= 200 && log.status < 300
                              ? '#4caf50' // Green for success
                              : log.status >= 300 && log.status < 400
                              ? '#2196f3' // Blue for redirects
                              : log.status >= 400 && log.status < 500
                              ? '#ff9800' // Orange for client errors
                              : log.status >= 500
                              ? '#f44336' // Red for server errors
                              : 'black', // Default
                          fontWeight: 'bold',
                        }}
                      >
                        {log.status} - {getStatusDescription(log.status)}
                      </TableCell>
                      <TableCell>{log.responseTime}</TableCell>
                      <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No logs found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalLogs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Layout>
  );
};

export default Logger;
