import Home from "./pages/home/Home";
import { Routes, Route } from "react-router-dom";
import "./style/dark.scss";
import React, { useEffect, useState } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import NotFound from "./pages/404/404";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import PrivateRoute from "./hooks/PrivateRoute";
import { isUserLoggedIn } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import Reset from "./pages/reset";
import Register from "./features/auth/Register";
import Login from "./features/auth/Login";
import VerifyAccount from "./features/auth/VerifyAccount";
import Innovations from "./pages/home/Innovations"
import InvestorsPage from "./pages/home/investors";
import InnovatorsPage from "./pages/home/innovators";
import Dashboard from "./pages/home/dashboard";
import AddInnovation from "./features/innovations/AddInnovation";
import InnovationDetail from "./features/innovations/InnovationDetail";
import EditInnovation from "./features/innovations/EditInnovation";
import Profile from "./pages/home/Profile";
import PartnersPage from "./pages/home/partners";
import InnovatorDetailPage from "./pages/home/InnovatorDetailPage";

import InvestorDetailPage from "./pages/home/InvestorDetailPage";
import ManagerUsers from "./pages/home/manageUsers";
import Message from "./pages/message";
import ReceivedMessages from "./pages/message/receivedMessages"; // Import ReceivedMessages
import MessageDetail from "./pages/message/inbox";
import { Notifications } from "@mui/icons-material";
import UserDetailPage from "./pages/home/userDetail";
import AuditTrail from "./pages/home/audit";
import Logger from "./pages/home/logger";
import ForgotPassword from "./features/auth/ForgotPassword";

function App() {

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Run the function immediately on mount to detect initial screen size
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.authenticate, dispatch]);
  return (
    <div className="container">
    <div className={isMobile ? "mobile-menu" : "menu"}>
      
    </div>

      <Routes>
        <Route path="/">
          <Route path="login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="register" element={<Register />} />
          <Route path="/forgotPassword" element={<ForgotPassword/>} />
          <Route path="/verify/:userId" element={<VerifyAccount />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/innovations" element={<Innovations/>} />
            <Route path="/innovation/add" element={<AddInnovation />} />
             <Route path="/innovation/:id" element={<InnovationDetail />} />
            <Route path="/investors" element={<InvestorsPage />} />
            <Route path="/innovate" element={<InnovatorsPage />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/innovator/:id" element={<InnovatorDetailPage/>} />
            <Route path="/investor/:id" element={<InvestorDetailPage/>} />
            <Route path="/manageUsers/:id" element={<UserDetailPage/>} />
            <Route path="/manageUsers" element={<ManagerUsers/>} />
            <Route path="/myprofile" element={<Profile/>}/>
            <Route path="/innovation/add" element={<AddInnovation />} />
            <Route path="/innovation/:id/edit" element={<EditInnovation />} />
            <Route path="/notifications" element={<Notifications/>} />
            <Route path="/message" element={<Message />}   />
            <Route path="/message/:messageId" element={<MessageDetail/>} />
            <Route path="/AuditTrail" element={<AuditTrail/>}   />
            <Route path="/logger" element={<Logger/>}   />
           
            <Route path="/messages/:userId" element={<ReceivedMessages />}
           
             /> 
            
          </Route>
        </Route>
        <Route path="*" element={<NotFound />}>
          {" "}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
