import React, { useState } from "react";
import { Box, styled } from "@mui/system";
import { Card, CardContent, Typography, Button, TextField, Alert } from "@mui/material";
import { Link } from "react-router-dom";

const BackgroundContainer = styled(Box)(() => ({
  backgroundImage: `url('path_to_your_background_image.jpg')`,
  backgroundSize: "cover",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
}));

const StyledCard = styled(Card)(() => ({
  maxWidth: 450,
  width: "100%",
  borderRadius: 12,
  background: "rgba(255, 255, 255, 0.9)",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  textAlign: "center",
}));

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    setLoading(true);

    try {
      const response = await fetch(
        "https://api.ghsdginnovations.org/api/auth/forgotpassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "User not found.");
      }

      setMessage("✅ Password reset link sent! Check your email.");
    } catch (err) {
      setError("❌ " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    
    <BackgroundContainer>
        
      <StyledCard>
        <CardContent>
            
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "#1E3A8A" }}>
            Forgot Password
          </Typography>
          <Typography sx={{ color: "#4B5563", marginBottom: 2 }}>
            Enter your email to receive a password reset link.
          </Typography>

          {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}
          {message && <Alert severity="success" sx={{ marginBottom: 2 }}>{message}</Alert>}

          <form onSubmit={handleForgotPassword}>
            <TextField
              type="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              sx={{ padding: "10px 0", fontSize: "16px", fontWeight: "bold" }}
            >
              {loading ? "Sending..." : "Send Reset Link"}
            </Button>
          </form>

          <Typography sx={{ marginTop: 3 }}>
            <Link to="/login" style={{ color: "#2563EB", textDecoration: "none", fontWeight: "500" }}>
              ⬅ Back to Login
            </Link>
          </Typography>
        </CardContent>
      </StyledCard>
    </BackgroundContainer>
  );
};

export default ForgotPassword;
