import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Grid,
  Alert,
  CircularProgress,
  Pagination,
  TextField
} from '@mui/material';
import axios from 'axios';
import Layout from './layout';

const AuditTrail = () => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLogs, setTotalLogs] = useState(0);

  // Filter states
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchDate, setSearchDate] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const fetchAuditLogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://api.ghsdginnovations.org/api/audits/logs', {
          params: { page: currentPage, limit: 10 },
          signal: controller.signal,
        });

        setAuditLogs(response.data.logs || []);
        setTotalPages(response.data.totalPages || 1);
        setTotalLogs(response.data.totalLogs || 0);
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError('Failed to fetch audit logs.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAuditLogs();

    return () => controller.abort();
  }, [currentPage]);

  useEffect(() => {
    const filtered = auditLogs.filter((log) => {
      const nameMatch = log.userId?.fullName?.toLowerCase().includes(searchName.toLowerCase()) || !searchName;
      const emailMatch = log.userId?.email?.toLowerCase().includes(searchEmail.toLowerCase()) || !searchEmail;
      const dateMatch = searchDate
        ? new Date(log.timestamp).toISOString().split('T')[0] === searchDate
        : true;

      return nameMatch && emailMatch && dateMatch;
    });

    setFilteredLogs(filtered);
  }, [auditLogs, searchName, searchEmail, searchDate]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Layout>
      <Box marginTop="64px" marginBottom="50px">
        <Typography variant="h4" gutterBottom>
          User Logs
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}

        {/* Filter Inputs */}
        <Box display="flex" gap={2} marginBottom={2}>
          <TextField
            label="Search by Name"
            variant="outlined"
            size="small"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <TextField
            label="Search by Email"
            variant="outlined"
            size="small"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
          <TextField
            label="Filter by Date"
            type="date"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
          />
        </Box>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                  <TableCell><strong>User</strong></TableCell>
                  <TableCell><strong>User's Email</strong></TableCell>
                  <TableCell><strong>User's Role</strong></TableCell>
                  <TableCell><strong>Action</strong></TableCell>
                  <TableCell><strong>URL</strong></TableCell>
                  <TableCell><strong>Timestamp</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogs.length > 0 ? (
                  filteredLogs.map((log) => (
                    <TableRow key={log._id}>
                      <TableCell>
                        <Typography>{log.userId?.fullName || 'Unknown User'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{log.userId?.email || 'Unknown email'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{log.userId?.role || 'Unknown role'}</Typography>
                      </TableCell>
                      <TableCell>{log.action}</TableCell>
                      <TableCell>{log.url}</TableCell>
                      <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No logs found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Pagination */}
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default AuditTrail;
